<template>
  <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4>
                     الملاحظات الصحية
                </h4>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>
                                    الطالب
                                </th>
                                <th>
                                    الحالة
                                </th>
                                <th>
                                    التاريخ
                                </th>
                                <th>
                                    مقبول
                                </th>
                                <th>
                                    بواسطة	
                                </th>
                                <th>
                                    حذف
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="rate in rates" :key="rate._id">
                                <td>
                                    {{ rate.name }}
                                </td>
                                <td>
                                    {{ rate.content }}
                                    <a href="javascript:;" @click="editRate(rate)"><i class="fa fa-edit"></i></a>
                                </td>
                                <td>
                                    {{ rate.date }}
                                </td>
                                <td>
                                    <span v-if="rate.status" class="text-success">
                                        <i class="fa fa-check"></i> مقبول
                                    </span>
                                    <button @click="accept(rate._id)" v-if="!rate.status" class="btn-success btn-sm btn">
                                        <i class="fa fa-check"></i> قبول
                                    </button>
                                </td>
                                <td>
                                    {{ rate.from == 'admin' ? 'المسؤول' : 'ولي الامر' }}
                                </td>
                                 <td>
                                    <button class="btn btn-danger btn-sm" @click="deleteHealth(rate._id)">
                                        <i class="fa fa-trash"></i> حذف
                                    </button>
                                 </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        vSelect,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            rates: [],
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    created(){
        if(!checkPer("students|rates")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getrates();
    },
    methods: {
        accept(id){
            var g = this;
            $.post(api + '/user/students/healths/accept', {
                jwt: this.user.jwt,
                id: id
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getrates()
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        getrates(){
            var g = this;
            $.post(api + '/user/students/healths', {
                jwt: this.user.jwt,
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.rates = r.response
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        deleteHealth(id){
            if(confirm("متأكد من حذف الحالة الصحية؟")){
                var g = this;
                $.post(api + '/user/students/health/delete', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    g.getrates()
                }).fail(function(){
                    alert("حدث خطأ")
                })
            }
        },
        editRate(rate){
            var g = this;
            var t = prompt("الحالة", rate.content);
            if(t!= "" && t){
                $.post(api + '/user/students/health/edit', {
                    jwt: g.user.jwt,
                    id: rate._id,
                    content: t
                }).then(function(r){
                    g.getrates()
                }).fail(function(){
                    alert("حدث خطأ")
                })
            }
        }
    }
}
</script>

<style>
@import url(https://cdnjs.cloudflare.com/ajax/libs/vue-select/3.10.0/vue-select.css);
#vs3__listbox{
    max-height: 150px !important;
    overflow: hidden !important;
}
</style>